const IndividualProjectBackground = ({ background }) => {
  return (
    <article>
      <h3>Project Background</h3>
      <p>{background}</p>
    </article>
  )
}

export default IndividualProjectBackground
